import './SiNoDropdown.css';

import React from 'react';

const SiNoDropdown = ({ handleValueChange, isEditable, placeholder }) => {
    return (
        <>
            <div className='flex'>
                <label className="inline-label">
                    {placeholder}:
                </label>
                <select
                    onChange={handleValueChange}
                    className="dropdown inline-input-si-no"
                    disabled={!isEditable}
                >
                    <option value=''>-</option>
                    <option value='0'>No</option>
                    <option value='1'>Si</option>
                </select>
            </div>
        </>
    );
};

export default SiNoDropdown;
