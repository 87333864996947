import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const handleTokenExpiry = () => {
  localStorage.removeItem('salvatoriServiciosToken');
  window.location.reload();
}

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  timeout: 3000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('salvatoriServiciosToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && error?.response?.data?.error_message === 'Token expired') {
      try {
        const refreshResponse = await axios.post(`${API_URL}/api/auth/refresh`, {}, { 
          withCredentials: true 
        });

        if (refreshResponse?.status === 200) {
          const newToken = refreshResponse.data.token;
          localStorage.setItem('salvatoriServiciosToken', newToken);

          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          return axiosInstance(originalRequest);
        } else {
          handleTokenExpiry();
        }
      } catch (refreshError) {
        handleTokenExpiry();
        return Promise.reject(refreshError);
      }
    }

    if (error?.response?.status === 401) {
      handleTokenExpiry();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;