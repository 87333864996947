import { openDB } from 'idb';

const dbName = 'pwa-db';
const storeName = 'formDataSalServicios';
const dbVersion = 2;

const dbPromise = openDB(dbName, dbVersion, {
    upgrade(db) {
        db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
    },
});

export const saveFormDataInIdb = async (data) => {
    const db = await dbPromise;
    await db.add(storeName, data);
};

export const getFormDataFromIdb = async () => {
    const db = await dbPromise;
    return await db.getAll(storeName);
};

export const deleteFormData = async (id) => {
    const db = await dbPromise;
    try {
        await db.delete(storeName, id);
    } catch (error) {
        console.error(error);
    }
};
