import React, { useState } from 'react';
import './MultiSelectModal.css'; // Ensure you create this CSS file for styling

const MultiSelectModal = ({ options, selectedValues, handleValueChange, isEditable, title, placeholder }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempSelectedValues, setTempSelectedValues] = useState([...selectedValues]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const newSelectedValues = event.target.checked
            ? [...tempSelectedValues, value]
            : tempSelectedValues.filter((selectedValue) => selectedValue !== value);

        setTempSelectedValues(newSelectedValues);
    };

    const handleOkClick = () => {
        handleValueChange(tempSelectedValues);
        setIsModalOpen(false);
    };

    return (
        <div className="flex">
            <label className="inline-label">
                {placeholder}:
            </label>
            <div className="modal-trigger" onClick={() => setIsModalOpen(true)}>
                {selectedValues.length > 0 ? selectedValues.join(', ') : '(Seleccione tareas)'}
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{title}</h3>
                        <ul className="modal-list">
                            {options.map((option) => (
                                <li key={option.id} className="modal-item">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={option.id}
                                            checked={tempSelectedValues.includes(option.id.toString())}
                                            onChange={handleCheckboxChange}
                                            disabled={!isEditable}
                                        />
                                        {option.name}
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div className="modal-ok-button-container">
                            <button onClick={handleOkClick} className="modal-ok-button">OK</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelectModal;
