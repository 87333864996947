import axiosInstance from '../axiosConfig'

export const fetchZones = async () => {
    try {
        const response = await axiosInstance.get('/php/pwa_get_zones.php');
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data;
    } catch (error) {
        console.error('Error getting zonas:', error);
    }
}

export const fetchPlazas = async (zone_id) => {
    try {
        const response = await axiosInstance.get('/php/pwa_get_plazas.php?zone_id=' + zone_id);
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data;
    } catch (error) {
        console.error('Error getting zonas:', error);
    }
}

export const fetchAssignable = async () => {
    try {
        const response = await axiosInstance.get('/php/pwa_get_assignable_users.php');
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data;
    } catch (error) {
        console.error('Error getting assignable:', error);
    }
}

export const getIncidentById = async (id) => {
    try {
        const response = await axiosInstance.get('/php/pwa_get_incidents.php?type=assigned&id=' + id);
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data;
    } catch (error) {
        console.error('Error getting incident:', error);
    }
}
export const fetchTasks = async (form) => {
    try {
        const response = await axiosInstance.get('/php/pwa_get_tasks.php?form=' + form);
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data;
    } catch (error) {
        console.error('Error getting tasks:', error);
    }
}