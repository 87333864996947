import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import './MainMenu.css';
import Header from './Header';

function MainMenu({ handleInicio, handleLogout }) {
  const navigate = useNavigate();

  const handleClickTareasRealizadas = () => {
    // alert('Este botón será habilitado pronto');
    // return;
    navigate('/task-list');
  };  

  const handleClick = (planilla) => {
    console.log(planilla, 'clicked')
    navigate(`/planilla/${planilla}`);
  };

  return (
    <div>
      <Header currentPage="MainMenu" onLogout={handleLogout} />
      <div className="form-container centered">
        {/* <p><button className="submit-button" onClick={() => handleClick('task-list')}>TAREAS REALIZADAS</button></p> */}
        <p><button className="submit-button" onClick={() => handleClick('limpieza')}>LIMPIEZA</button></p>
        <p><button className="submit-button" onClick={() => handleClick('corte')}>CORTE</button></p>
      </div>
    </div >
  );
}

export default MainMenu;
