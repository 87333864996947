import React, { useState, useEffect } from 'react';

import './Planilla.css';
import '../MainMenu.css';
import Header from '../Header';
import icon_camera from '../assets/icon_camera.png';
import icon_save from '../assets/icon_save.png';
import axiosInstance from '../axiosConfig'
import { saveFormDataInIdb, getFormDataFromIdb, deleteFormData } from '../helper/idb';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchZones, fetchPlazas, fetchAssignable } from './../helper/api';
import FilterableDropdown from './../components/FilterableDropdown';
import SiNoDropdown from '../components/SiNoDropdown';
import MultiSelectModal from '../components/MultiSelectModal';
import { fetchTasks } from '../helper/api';

const API_URL = process.env.REACT_APP_API_URL;

const MAX_IMAGES = 15;
const APP_VER = 'pwa1.0';

const showTasks = (planilla) => {
    return ['limpieza', 'hidrolavado'].includes(planilla);
}

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function Planilla({ type, handleLogout }) {
    const [observaciones, setObservaciones] = useState('');
    const [zonaValue, setZonaValue] = useState('');
    const [plazaValue, setPlazaValue] = useState('');
    const [hechoValue, setHechoValue] = useState('');
    const [tareasValues, setTareasValue] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedTaskNames, setSelectedTaskNames] = useState([]);
    const [asignarAValue, setAsignarAValue] = useState('');
    const [capturedImages, setCapturedImages] = useState([]);
    const [zones, setZones] = useState([]);
    const [plazas, setPlazas] = useState([]);
    const [asignables, setAsignables] = useState([]);
    const [options, setOptions] = useState([]);

    const location = useLocation();
    const serviceData = location.state?.anomaly;

    const isEditable = !serviceData;

    useEffect(() => {
        if (serviceData) {
            setZonaValue(serviceData.zone_id);
            setPlazaValue(serviceData.lugar_id);
            setObservaciones(serviceData.comment);
            setAsignarAValue(serviceData.user_id_assigned);

            const photos = serviceData.photos ? serviceData.photos.split(', ') : [];
            const transformedPhotos = photos.map(photo => API_URL + photo.replace('../', ''));
            setCapturedImages(transformedPhotos);
        }
        async function fetchData() {
            setOptions(await fetchTasks('limpieza') || []);
        }
        fetchData();
        
    }, [serviceData]);

    const navigate = useNavigate();

    const clearAllFields = () => {
        // setVolume('');
        // setDate('');
    };

    const handleBack = () => {
        clearAllFields();
        navigate(-1);
    };

    const saveImagesByIncidentId = async (id, capturedImages) => {
        try {
            for (const image of capturedImages) {
                const img = new Image();
                img.src = image;

                img.onload = async () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    let newWidth = originalWidth;
                    let newHeight = originalHeight;

                    if (originalWidth > originalHeight) {
                        if (originalWidth > 1024) {
                            newWidth = 1024;
                            newHeight = (originalHeight * 1024) / originalWidth;
                        }
                    } else {
                        if (originalHeight > 1024) {
                            newHeight = 1024;
                            newWidth = (originalWidth * 1024) / originalHeight;
                        }
                    }
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    const resizedImage = canvas.toDataURL('image/jpeg');
                    const byteString = atob(resizedImage.split(',')[1]);
                    const mimeString = 'image/jpeg';
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });

                    const formData = new FormData();
                    formData.append('uploaded_file', blob, generateRandomString(6) + '.jpg');

                    try {
                        const response = await axiosInstance.post(
                            `/php/pwa_upload_image.php?service=${type}&id=${id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        if (response?.status !== 200) {
                            throw new Error('Network response was not ok');
                        }

                        const result = response.data;
                    } catch (error) {
                        await saveFormDataInIdb({ incident_id: id, image });
                    }
                }
            }
        } catch (error) {
            await saveFormDataInIdb({ incident_id: id, image });
        }
    };

    useEffect(() => {
        fetchZones().then((zonesData) => {
            if (!zonesData) {
                alert('No se puedo conectar al servidor');
            }
            if (Array.isArray(zonesData)) {
                setZones(zonesData);
            }
        });

        const handleOnline = async () => {
            try {
                const formDataList = await getFormDataFromIdb();
                for (const data of formDataList) {
                    if (data.body) {
                        const response = await saveServiceToServer(data.body);
                        await saveImagesByIncidentId(response.id, data.images);
                        await deleteFormData(data.id);
                    } else {
                        await saveImagesByIncidentId(data.id, [data.image]);
                        await deleteFormData(data.id);
                    }
                }
            } catch (error) {
                console.error('Error in handling online', error);
            }
        };

        const checkForRecordsToSync = async () => {
            if (navigator.onLine) {
                await handleOnline();
            } else {
            }
        };

        checkForRecordsToSync();

        // window.addEventListener('online', handleOnline);
        // return () => {
        //     window.removeEventListener('online', handleOnline);
        // };
        return () => {
            // console.log('Anomaly unmounted');
        };
    }, []);

    const getBody = () => {
        return {
            plaza_id: -1, // remove?
            ubicacion_id: plazaValue,
            observaciones: observaciones,
            hecho: hechoValue,
            app_version: APP_VER,
            tasksDone: tareasValues,
            fecha: parseInt(Date.now() / 1000),
            type: type,
        };
    }

    const generateForm = (body) => {
        const formDataReturn = new FormData();
        for (const key in body) {
            if (body.hasOwnProperty(key)) {
                formDataReturn.append(key, body[key]);
            }
        }
        return formDataReturn;
    }

    const saveServiceToServer = async (body) => {
        try {
            const response = await axiosInstance.post('/php/pwa_save_service.php', generateForm(body));
            if (!response || response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            const result = response.data;
            await saveImagesByIncidentId(result.id, capturedImages);
            return result;
        } catch (error) {
            console.error('Error saving data: (catch else)', error);
            await saveFormDataInIdb({ body, images: capturedImages });
        }
    }

    // error handling
    const handleSave = async () => {
        let errors = false;
        if (observaciones.trim() === '') {
            errors = true;
            setMessageVisibility('error-message-observaciones', true);
        }

        if (zonaValue === '' || zonaValue === 0) {
            errors = true;
            setMessageVisibility('error-message-zona', true);
        }

        if (plazaValue === '' || plazaValue === '-') {
            errors = true;
            setMessageVisibility('error-message-plaza', true);
        }

        if (hechoValue === '') {
            errors = true;
            setMessageVisibility('error-message-hecho', true);
        }

        if (errors) {
            return;
        }

        const body = getBody();

        await saveServiceToServer(body);
        navigate('/main-menu');
    };

    useEffect(() => {
        fetchAssignable().then(assignableData => {
            if (Array.isArray(assignableData)) {
                setAsignables(assignableData);
            }
        });
    }, []);

    useEffect(() => {
        if (zonaValue) {
            fetchPlazas(zonaValue).then((plazasData) => {
                if (Array.isArray(plazasData)) {
                    setPlazas(plazasData);
                } else {
                    console.error('Fetched plazas data is not an array:', plazasData);
                }
            });
        } else {
            setPlazas([]);
        }
    }, [zonaValue]);

    const handleZonaChange = (e) => {
        setZonaValue(e.target.value);
        setMessageVisibility('error-message-zona', false);
    };

    const setMessageVisibility = (elementId, isVisible) => {
        const element = document.getElementById(elementId);
        element.style.display = isVisible ? 'block' : 'none';
    }

    const handleHechoChange = (e) => {
        setHechoValue(e.target.value);
        setMessageVisibility('error-message-hecho', false);
    };

    const handleTareasChange = (newSelectedValues) => {
        setSelectedValues(newSelectedValues);

        const intValues = newSelectedValues.map(value => parseInt(value, 10));
        const selectedNames = intValues
        .map(value => {
            return options.find(option => {
                return option.id === value;
            })?.name
        }
        )
        .filter(name => {
            return name;
        });
        setSelectedTaskNames(selectedNames);
        setTareasValue(selectedNames.join('|'));
    };
    
    const handlePlazaChange = (e) => {
        setPlazaValue(e.target.text === '-' ? e.target.text : e.target.value);
        setMessageVisibility('error-message-plaza', false);
    };

    const handleObservacionesChange = (e) => {
        setObservaciones(e.target.value);
        setMessageVisibility('error-message-observaciones', false);
    };

    const handleImageCapture = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + capturedImages.length > MAX_IMAGES) {
            alert(`Solo se permiten ${MAX_IMAGES} imágenes`);
            return;
        }

        const newImages = files.map(file => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newImages).then(images => {
            setCapturedImages(prevImages => [...prevImages, ...images]);
        });
    };

    const handleDeleteImage = (index) => {
        setCapturedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const capitalizeFirstLetter = (string) => {
        if (string.length === 0) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getDate = () => {
        const date = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('es-ES', options).format(date);
    }

    const daysOfWeek = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    const today = new Date().getDay();
    const adjustedToday = (today === 0) ? 6 : today - 1;

    return (
        <div>
            <Header currentPage="Anomaly" onLogout={handleLogout} onBack={handleBack} />
            <h2>{capitalizeFirstLetter(type)}</h2>
            <div className='text-date'>Hoy es {getDate()}</div>
            <div className="form-container centered">

                <div className='flex'>
                    <label className="inline-label">
                        Comuna/Zona:
                    </label>
                    <FilterableDropdown
                        zones={zones}
                        zonaValue={zonaValue}
                        handleZonaChange={handleZonaChange}
                        isEditable={isEditable}
                        placeholder="Seleccione una zona"
                    />
                    <div className='flex error-message-form hidden' id="error-message-zona">
                        La zona es requerida
                    </div>
                </div>

                <div className='flex'>
                    <label className="inline-label">
                        Plaza:
                    </label>
                    <FilterableDropdown
                        zones={plazas}
                        zonaValue={plazaValue}
                        handleZonaChange={handlePlazaChange}
                        isEditable={isEditable}
                        placeholder="Seleccione una plaza"
                    />
                    <div className='flex error-message-form hidden' id="error-message-plaza">
                        La plaza es requerida
                    </div>
                </div>

                <div className='flex'>
                    <label className="inline-label">
                        Ubicación:
                    </label>
                    <select
                        value={plazaValue}
                        className="dropdown inline-input"
                        disabled={true}
                    >
                        {plazas.map((plaza) => (
                            <option key={plaza.id} value={plaza.id}>{plaza.name}</option>
                        ))}
                    </select>
                </div>

                <SiNoDropdown
                    handleValueChange={handleHechoChange}
                    isEditable={isEditable}
                    placeholder="Hecho"
                />
                <div className='flex error-message-form hidden' id="error-message-hecho">
                    Este valor es requerido
                </div>

                { showTasks(type) &&
                    <MultiSelectModal
                        options={options}
                        selectedValues={selectedTaskNames}
                        handleValueChange={handleTareasChange}
                        isEditable={true}
                        title="Tareas realizadas"
                        placeholder="Tareas realizadas"
                    />
                }

                <div className="form-group">
                    <label className="inline-label">
                        <>
                            <img src={icon_camera} width="50px" alt="Camera Icon" />
                            {isEditable && (
                                <input
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    style={{ display: 'none' }}
                                    onChange={handleImageCapture}
                                    multiple
                                />
                            )}
                        </>
                    </label>
                    <div className="images-grid">
                        {capturedImages.map((image, index) => (
                            <div key={index} className="image-container">
                                <img className='image-item' src={image} alt={`Captured ${index + 1}`} />
                                {isEditable && (
                                    <button onClick={() => handleDeleteImage(index)}>Eliminar</button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <div className='flex'>
                    <label className="inline-label">
                        Observaciones:
                    </label>
                    <textarea
                        value={observaciones}
                        onChange={handleObservacionesChange}
                        className="textarea inline-input"
                        readOnly={!isEditable}
                        rows="5"
                    />
                </div>
                <div className='flex error-message-form hidden' id="error-message-observaciones">
                    Las observaciones son requeridas
                </div>

                {isEditable && (
                    <div className="form-group button-save">
                        <button className="submit-button image-button" onClick={handleSave}>
                            <img src={icon_save} width="50px" alt="Guardar" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Planilla;
